// PAGE
.transactionModelCard {
  position: absolute;
  width: 15rem;
  padding: 0.75rem 1rem;
  background: var(--cards-card-background-dark, rgba(255, 255, 255, 0.85));
  border-radius: 0;
  border-bottom: 1px solid var(--cards-card-outline, #D4D4D4);

  &.expanded {
    background: #1C70EE !important;
    
    .header4 {
      color: #FFF;
    }

    .toggleIcon path {
      fill: #FFF;
    }
  }

  .header4 {
    margin-bottom: 0;
    font-size: 1rem;
    color: var(--colors-brand-primary-60, #3D4357);
    line-height: 25px;
  }

  .transactionAssumptionsControls {
    position: absolute;
    top: 0;
    width: 60vw;
    margin-left: 14rem;
    padding: 0.75rem 1rem;
    background: #FFF;
    border: 3px solid #1C70EE;
    border-radius: 0px 8px 8px 8px;
    box-shadow: 0px 2px 4px 0px rgba(147, 155, 184, 0.40);
    z-index: 3;

    &.withTable {
      overflow: scroll;
    }
  }

  .assumptionsInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 15rem;
    
    .assumptionLabel {
      align-items: center;
      gap: 0.5rem;
      font-size: 14px;
      font-weight: 600;
      color: var(--colors-brand-primary-60, #3D4357);
      line-height: 150%;
    }

    .assumptionSubLabel {
      font-size: 14px;
      font-weight: 400;
      color: var(--colors-brand-primary-40, #636879);
      line-height: 150%;
    }

    .assumptionValue {
      width: 100%;
    }

    .subAssumptionContainer {
      gap: 1rem;

      .transactionModelStepContent {
        position: absolute !important;
        top: 3.5rem;
        right: -1rem;

        path {
          fill: rgba(149, 4, 4, 1);
        }
      }
    }
  }
}

.deleteIcon {
  align-self: flex-end;
  margin-left: 0.5rem;

  path {
    fill: rgba(149, 4, 4, 1);
  }
}

// METRICS
.metricCard {
  display: flex;
  max-height: 15rem;
  padding: 1.5rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  align-self: stretch;
  text-align: center;
  border-radius: 6px;
  border: 1px solid var(--cards-card-outline, #D4D4D4);
  background: var(--cards-chat-box, #F1F5FF) !important;

  &.small {
    max-height: 11rem;
  }

  &.exitMetric .metricValue {
    color: var(--colors-green-60, #43A854);
  }

  .metricValue {
    font-size: 2rem;
    font-weight: 700;
    line-height: 60px;
    color: var(--components-badge-info-color, #1C4E98);
    text-align: center;

    &.subtitle {
      font-size: 1.5rem;
    }
  }

  .metricName {
    font-size: 1rem;
    font-weight: 600;
    line-height: 25px;
    color: var(--colors-brand-primary-40, #636879);

    &.subtitle {
      line-height: 5px;
      font-size: 0.9rem;
    }
  }
}

// WIZARD
.transactionModelSteps {
  display: flex;
  position: relative;
  gap: 2rem;
  justify-content: space-evenly;
  padding: 0;
  list-style: none;
}

.transactionModelSteps .dividerContainer {
  position: absolute;
  width: 90%;
  height: 2px;
  margin-top: 1rem;
  background: var(--colors-brand-primary-10, #A0A2AC);
}

.transactionModelSteps .dividerContainer .divider {
  width: 0;
  height: 2px;
  background-color: var(--components-button-color, #1C70EE);
}

.transactionModelSteps .transactionModelStep {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  text-align: center;
}

.transactionModelSteps .transactionModelStep .stepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 1rem;
  font-weight: 700;
  width: 2rem;
  height: 2rem;
  line-height: 1.8rem;
  text-align: center;
  border: 1px solid #636879;
  border-radius: 50%;
  background: rgb(240, 240, 240);
  z-index: 1;
}

.transactionModelSteps .transactionModelStep .stepNumber,
.transactionModelSteps .transactionModelStep .stepName {
  color: var(--colors-brand-primary-40, #636879);
  font-weight: 600;
}

.transactionModelSteps .transactionModelStep.active .stepNumber {
  color: var(--components-button-color, #1C70EE);
  border: 2px solid var(--components-button-color, #1C70EE);
}

.transactionModelSteps .transactionModelStep.active .stepName {
  color: var(--colors-brand-primary-60, #3D4357);
}

.transactionModelSteps .transactionModelStep.done .stepNumber {
  color: var(--components-button-color, #1C70EE);
  border: 1px solid var(--components-button-color, #1C70EE);
  background: var(--components-badge-info-background-color, #B8CEF9);
}

.transactionModelSteps .transactionModelStep.done .stepNumber svg {
  width: 1rem !important;
  height: 1rem !important;
}

.transactionModelSteps .transactionModelStep.done .stepNumber svg path {
  fill: var(--components-button-color, #1C70EE);
}

.transactionModelSteps .transactionModelStep.done .stepName {
  color: var(--colors-brand-primary-60, #3D4357);
}

/* step content */
.transactionModelStepContent {
  flex-direction: column;
}

.transactionModelStepContent .transactionModelStepHeader {
  align-self: center;
  margin-bottom: 0.5rem;
  font-size: 22px;
  font-weight: 500;
  color: var(--colors-brand-primary-80, #303545);
}

.transactionModelStepContent .amplify-field {
  width: 100%;
}

.transactionModelStepContent .amplify-input, .transactionModelStepContent .amplify-select {
  height: 42px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.transactionModelStepContent .amplify-select, .transactionModelStepContent select {
  cursor: pointer;
}

.transactionModelStepContent .amplify-label {
  font-size: 1rem;
  font-weight: 400;
  color: var(--components-badge-info-color, #1C4E98);
}

.transactionModelStepContent .assumptionSubLabel {
  font-size: 1rem;
  color: var(--components-field-label-color, #3D4357);
}

.transactionModelStepContent .tableCard .amplify-input,
.transactionModelStepContent .tableCard .amplify-select,
.transactionModelStepContent .tableCard select {
  height: 35px;
  padding: 6px 12px;
  font-size: 14px;
  color: rgb(61, 67, 87);
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid var(--components-fieldcontrol-border-color, #D4D4D4);
}

/* transaction types */
.transactionTypeContainer .transactionTypes {
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.transactionTypeContainer .transactionTypes .transactionType {
  padding: 0.75rem 1rem;
  border: 1px solid var(--cards-card-outline, #D4D4D4);
  border-radius: 4px;
  background: #FFF;
}

.transactionTypeContainer .transactionTypes .transactionType:hover {
  border: 1px solid var(--cards-card-outline, #D4D4D4);
  background: var(--components-button-hover-background-color, #D9E6FF); 
}

.transactionTypeContainer .transactionTypes .transactionType .amplify-checkbox__label {
  margin-left: 1rem;
}

.transactionTypeContainer .transactionTypes .transactionType .amplify-checkbox__button {
  box-shadow: none;
}

.transactionTypeContainer .transactionTypes .transactionType .amplify-checkbox__button::before {
  border-color: rgb(138, 141, 153);
}

.transactionTypeContainer .transactionTypes .transactionType.active {
  border: 1px solid var(--components-button-color, #1C70EE);
  background: var(--components-button-hover-background-color, #D9E6FF);
}

.transactionTypeContainer .transactionTypes .transactionType .amplify-checkbox__button[data-checked='true'] span {
  background-color: var(--components-checkbox-icon-background-color, #2962E3);
}

.transactionTypeContainer .roundName {
  flex-direction: column;
  gap: 0.5rem;
}

/* table */
.transactionModelStepContent table {
  table-layout: fixed;
  border-collapse: separate !important;
  border-spacing: 0;
  border-radius: 4px;
  border: 1px solid var(--table-cell-outline, #DCDEE0);
}

.transactionModelStepContent .groupTableHeader {
  font-weight: 600 !important;
  color: var(--colors-brand-primary-60, #3D4357) !important;
  background: var(--colors-brand-secondary-20, #E4EDFF);
  border: none;
  border-left: 1px solid var(--table-cell-outline, #DCDEE0);
}

.groupTableHeader.groupTableHeaderContainer {
  background: var(--table-cell-header-1, #B8CEF9);
}

th.sticky, td.sticky {
  position: sticky;
  left: 0;
  z-index: 2;
}

.transactionModelStepContent .tableCell {
  background: var(--cards-card-background-dark, rgba(255, 255, 255, 0.85));
}

.transactionModelStepContent tr:hover {
  background: var(--cards-card-background-dark, rgb(200, 200, 200));
}