/* OPPORTUNIY STAGES */
.opportunityStageContainer {
    display: flex;
    gap: 5px;
}

.opportunityStage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    position: relative;
    padding: 2px 20px 2px 20px;
    background-color: var(--amplify-colors-overlay-10);
    text-align: center;
    cursor: pointer;
}

.opportunityStage:hover {
    background-color: var(--amplify-colors-brand-primary-100) !important;
    color: #fff;
}

.opportunityStage:hover::after {
    border-left: 17px solid var(--amplify-colors-brand-primary-100) !important;
}

.opportunityStage.active {
    background-color: var(--amplify-colors-brand-primary-100);
    font-weight: 500;
    color: #fff;
}

.opportunityStage.active::after {
    border-left: 17px solid var(--amplify-colors-brand-primary-100);
}

.opportunityStage.completed {
    background-color: #7fb765;
    font-weight: 500;
}

.opportunityStage.completed::after {
    border-left: 17px solid #7fb765;
}

.opportunityStage::before, .opportunityStage::after {
    content: "";
    position: absolute;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 17px solid var(--amplify-colors-overlay-10);
    z-index: 2;
}

.opportunityStage::before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}

.opportunityStage:first-child::before {
    border: none;
}

.opportunityStage:last-child::after {
    border: none;
}

.opportunityStage:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.opportunityStage:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.opportunityStage span {
    position: relative;
    font-size: 14px;
}

/* CREATE OPPORTUNITY */
.opportunityForm {
    width: 25rem;
    height: auto;
}

/* DELETE OPPORTUNITY */
.targetCompanyButtonPanel {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.deleteIcon {
    width: 10px !important;
    cursor: pointer;
}

.createOpportunityForm button {
    width: fit-content;
}

.logout {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.logout:hover svg path {
    fill: black;
}

/* TARGET COMPANY */
.targetCompany {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
}

.targetCompanySelect select {
    padding-left: 0;
    font-size: 14px;
}

/* SUMMARY DASHBOARDS */
.summaryContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 1rem;
}

.summarySection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 40rem;
}

.transactionSection {
    margin-top: 1rem;
}

.summaryTileContainer {
    display: flex;
    gap: 3rem;
    /* margin-bottom: 2rem; */
}

.summaryTile.summaryTileMetric {
    text-align: center;
}

.summaryTile.summaryTileMetric .tableCellLabel input {
    border: none;
    font-size: 2rem;
    pointer-events: none;
}

.summaryTileTitle {
    font-weight: 300;
    font-size: 16px;
    margin-top: 0;
}

.summaryTile.summaryTileChart {
    width: 35rem;
}

.summaryTileContent.summaryTileChart {
    width: 25rem;
    height: 22rem;
}

.summaryTileContent.summaryTileMetric {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.dataCompletenessContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dataItem {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.dataCompleteness {
    display: flex;
    align-items: center;
    width: 15rem;
    height: 1rem;
    background-color: var(--amplify-colors-overlay-10);
}

.dataCompletenessPercentage {
    width: 60%;
    height: 1rem;
    background-color: #7fb765;
}

.lineItem {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.lineItemTitle {
    text-decoration: underline;
}

.lineItemValue {
    font-weight: 600;
}

.lineItem.lineItemTotal {
    align-self: flex-end;
    border-top: 1px solid black;
}

.keyAssumptionsContainer, .sourcesContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
