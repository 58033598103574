/* grid */
.financialsGrid .reactgrid .rg-cell {
    padding: 10px 12px !important;
    color: #3D4357 !important;
    background: #FFF;
    border: 1px solid #DCDEE0 !important;
    justify-content: start;
}

.financialsGrid .reactgrid .header-section {
    font-weight: 600 !important;
    border-right: black 0px solid !important;
    border-left: black 0px solid !important;
    border-bottom: black 2px solid !important;
}

.financialsGrid .reactgrid .rg-header-cell {
    font-weight: 600 !important;
}

.financialsGrid .reactgrid .rg-horizontalChevron-cell {
    font-weight: 600 !important;
    background: #E4EDFF !important;
    justify-content: flex-end;
}

.financialsGrid .reactgrid .rg-horizontalChevron-cell.year {
    background: #E4EDFF !important;
}

.financialsGrid .reactgrid .rg-horizontalChevron-cell .chevron {
    margin-left: 1rem;
    cursor: pointer;
}

.financialsGrid .reactgrid .rg-horizontalChevron-cell:first-child {
    justify-content: flex-start;
    background: #FFF !important;
}

/* output table */
.outputTable .amplify-table__head .amplify-table__row .amplify-table__th {
    background: var(--table-cell-header, #E4EDFF);
}

.outputTable .amplify-table__head .amplify-table__row .amplify-table__th:first-child {
    border: none;
    border-top-left-radius: 0.5rem;
}

.outputTable .amplify-table__head .amplify-table__row:first-child .amplify-table__th:last-child {
    border-top: none;
    border-right: none;
    border-top-right-radius: 0.5rem;
}

.outputTable .amplify-table__body .amplify-table__row:last-child .amplify-table__th:first-child,
.outputTable .amplify-table__body .amplify-table__row:last-child .amplify-table__td:first-child {
    border-left: none;
    border-bottom: none;
    border-bottom-left-radius: 0.5rem;
}

.outputTable .amplify-table__body .amplify-table__row:last-child .amplify-table__td:last-child {
    border-right: none;
    border-bottom: none;
    border-bottom-right-radius: 0.5rem;
}

.outputTable .amplify-table__body .amplify-table__row .amplify-table__td:first-child {
    border-left: none;
}

.outputTable .amplify-table__body .amplify-table__row .amplify-table__th:first-child,
.outputTable .amplify-table__body .amplify-table__row .multiplesBucketCell {
    background: var(--table-cell-header, #E4EDFF);
}

.outputTable .amplify-table__body .totalRow .totalRowCell {
    border-top: 1px solid var(--table-cell-outline, #D4D4D4);
    border-left: 1px solid var(--table-cell-outline, #D4D4D4);
    background: #FFF;
}

.outputTable .amplify-table__body .amplify-table__td {
    background: #FFF;
}

.financialsTable {
    display: flex;
    flex-direction: column;
}

.financialsTable .tableRowHeader {
    /* border-bottom: 1px solid var(--table-cell-outline, #D4D4D4); */
    background: var(--table-cell-header, #E4E8F3);
}

.financialsTable .tableRowHeader .tableCell {
    color: var(--colors-brand-primary-60, #3D4357);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-size: 14px;
    font-weight: 600;
}

.financialsTable .tableRowBody {
    background-color: #FFF;
}

.financialsTable .tableRowBody:last-child .tableCell:first-child {
    border-bottom-left-radius: 1rem;
}

.financialsTable .tableRowBody:last-child .tableCell:last-child {
    border-bottom-right-radius: 1rem;
}

.financialsTable .tableRowBody .tableCell {
    border: none;
}

.primaryInvestmentTable tbody td {
    background-color: #FFF;
}

.transactionModelSection {
    gap: 0;
}

.transactionAssumptionsControls {
    flex-direction: column;
}

.transactionAssumptionsControls .assumptionsInput {
    align-items: center;
}

.transactionAssumptionsControls .addNewButton {
    margin: 0px 0px 0px -4rem;
    padding: 0 6px;
}

.transactionSetupProgressBarContainer {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.transactionSetupProgressBarContainer .transactionSetupProgressBar {
    width: 10rem;
}

.transactionSetupProgressBarContainer .transactionSetupProgressBar line:last-of-type {
    stroke: var(--amplify-colors-brand-secondary-60);;
}

.tableUpdaterButton, .tableCalculateButton {
    align-self: flex-start;
    margin: 0 0 1rem auto;
    background-color: var(--amplify-colors-green-60);
    background-image: none;
    border-color: transparent;
}

.tableUpdaterButton:hover {
    background-image: none;
    background-color: var(--amplify-colors-green-80) !important;
    border: 1px solid var(--amplify-colors-green-80);
}

.tableCalculateButton {
    background-color: transparent;
    border: 1px solid var(--amplify-colors-brand-secondary-60);
    border-radius: 2rem;
}

.tableCalculateButton:hover {
    color: var(--amplify-colors-brand-secondary-60);
    background-image: none;
    background-color: var(--amplify-colors-overlay-10);
}

.table {
    display: flex;
    flex-direction: column;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    margin-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 10px;
}

.dataRoom {
    overflow: hidden;
}

.dataRoom .table {
    width: 75vw;
}

.tableTitleInput {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.transactionModelSection .table {
    margin-right: 0.1rem;
}

.summaryContainer .table, .transactionModelSection .table {
    width: 78vw;
}

.transactionModelSection .transactionModelControls {
    flex-direction: column;
    gap: 0;
}

.transactionModelSection .transactionModelControls .table {
    width: 24rem;
}

.transactionModelOutput {
    flex-direction: column;
    gap: 0rem;
    border-top-left-radius: 0;
}

.transactionModelOutput .baseCard.overlayCard {
    padding: 1rem;
}

.transactionModelSection .transactionModelOutput .summaryTile.summaryTileMetric {
    display: flex;
    justify-content: center;
    width: 12rem;
    height: 8rem;
}

.transactionModelSection .transactionModelOutput .summaryTile.summaryTileChart {
    width: 25rem;
}

.transactionModelSection .transactionModelOutput .summaryTileContent.summaryTileChart {
    /* width: 15rem; */
    /* height: 20rem; */
}

.tableBody {
    /* Commenting out height to allow for content to show, will remove animation */
    /* max-height: 500px; */
    transition: all 0.2s ease;
    overflow: scroll;
}

.tableBody.collapse {
    max-height: 0;
    transition: all 0.2s ease;
}

.tableTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    color: var(--amplify-colors-brand-primary-60);
    background-color: var(--amplify-colors-shadow-tertiary);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
}

.tableTitle.alternate {
    font-size: 1.5rem;
}

.tableTitle svg {
    margin-left: auto;
    margin-right: 1rem;
}

.tableTitle svg path {
    fill: var(--amplify-colors-brand-primary-60);
}

.tableBody {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tableWithDelete .amplify-button--destructive {
    opacity: 0;
}

.tableWithDelete tr:hover .amplify-button--destructive {
    opacity: 1;
}

.tableWithDelete .groupTableHeader:last-of-type {
    width: 4rem !important;
}

.groupTableHeader {
    padding: 10px 12px !important;
    text-align: center;
    font-weight: 600 !important;
    color: var(--colors-brand-primary-60, #3D4357) !important;
    background: var(--colors-brand-secondary-40, #E4EDFF);
    border-top: 1px solid var(--table-cell-outline, #DCDEE0);
    border-left: 1px solid var(--table-cell-outline, #DCDEE0);
}

.groupTableHeader.border {
    border: 1px solid var(--amplify-colors-brand-secondary-60);
}

.groupTableHeaderDivider {
    color: var(--colors-brand-primary-60, #3D4357) !important;
    background: var(--colors-brand-secondary-20, #E4EDFF);
    border-top: 1px solid var(--table-cell-outline, #DCDEE0) !important;
    border-left: 1px solid var(--table-cell-outline, #DCDEE0) !important;
}

.groupTableHeaderNone, .cellNone {
    border-top: none !important;
    border-bottom: none !important;
    background: #FFF !important;
}

.tableHeader {
    border: none;
    color: var(--amplify-colors-brand-secondary-60);
}

.capTable tbody tr:last-child {
    background-color: #b7b7b7 !important;
}

.capTable tbody tr:last-child td {
    font-weight: 600;
}

.tableCell {
    background: #FFF;
    border-top: 1px solid var(--table-cell-outline, #DCDEE0);
    border-left: 1px solid var(--table-cell-outline, #DCDEE0);
}

.withBorderNone {
    border: none;
}

.transactionModelSection .tableCell.tableCellLabel {
    width: 20rem;
}

.transactionModelSection .tableCell .tableCellLabel label {
    font-weight: 500 !important;
    color: black !important;
}

.transactionModelSection .tableCell input, .tableCell .amplify-select__wrapper {
    width: 14rem;
}

.transactionModelSection .tableCell input, .tableCell select {
    width: 14rem;
    font-size: 14px;
    align-items: center;
    cursor: default;
}

.transactionModelSection .tableCell input:read-only {
    color: black;
    cursor: default;
    background-color: #FFF;
    pointer-events: none;
}

.addInvestorButton, .addInvestorButton:hover {
    margin: 2rem 0 1rem 0.5rem;
    color: var(--amplify-colors-blue-60);
}

.removeInvestorButton, .removeInvestorButton:hover {
    color: var(--amplify-colors-red-60);
}

.removeLineItemButton {
    width: 25px;
    height: 25px;
}

.tableWithDelete .tableCell:last-child {
    width: 1rem;
    text-align: center;
    border-right: 1px solid var(--amplify-colors-shadow-tertiary);
}

.valuationTable .tableHeader {
    border: 1px solid var(--amplify-colors-shadow-tertiary);
}

.valuationTable .tableHeader:first-child {
    border: none;
}

.chatGPT .tableCell {
    vertical-align: baseline;
    border: none;
    gap: 1rem;
}

.chatGPT .tableRowHeader {
    background-color: var(--amplify-colors-shadow-tertiary);
}

.chatGPT .tableRowHeader .tableCell {
    font-size: 18px;
    font-weight: 500;
    color: var(--amplify-colors-brand-secondary-60);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.chatGPT .tableRowBody .tableCell {
    padding: 0 2rem 1rem 1rem;
    font-size: 14px;
}

.chatGPT .tableRowBody .tableCell .amplify-text {
    margin: 1rem 0;
}

.selectTransaction {
    width: 25rem;
}

.selectTransaction div {
    cursor: pointer;
}

/* Custom ChatGPT table styles */
.chatGPT.custom .tableCell {
    border-color: var(--amplify-components-table-data-border-color);
    border-style: var(--amplify-components-table-data-border-style);
    border-width: var(--amplify-internal-table-td-border-width);
}

.chatGPT.custom .tableRowHeader .tableCell {
    font-size: 16px;
}

.chatGPT.custom .tableRowBody .tableCell {
    padding: 1rem;
}

.disclaimer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
}

.disclaimer .disclaimerMessage {
    color: var(--amplify-colors-font-error);
}

.totalRow {
    background-color: var(--amplify-colors-overlay-10) !important;
}

.totalRowCell {
    font-weight: bold;
    border: none !important;
}

.multiplesBucketCell {
    font-weight: bold;
}

.targetCompanyTableCell {
    color: var(--amplify-colors-blue-60);
    font-weight: 600;
    cursor: pointer;
}

.reactgrid-content .rg-pane .rg-cell.rg-number-cell {
    justify-content: start;
}
