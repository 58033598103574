.dashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dashboardControls {
    display: flex;
    gap: 2rem;
    align-items: flex-end;
}

.dashboard {
    display: flex;
    flex-direction: column;
}

.dashboard .dashboardButtons {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    width: 75vw;
    height: 3.5rem;
    justify-content: space-between;
}

.dashboard .dashboardButtons.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    height: 4rem;
    margin-top: 0;
    padding-top: 1rem;
    backdrop-filter: blur(2px);
    z-index: 1;
}

.dashboard .dashboardButton {
    align-self: flex-start;
}

.dashboard .dashboardButton.active {
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        var(--amplify-colors-brand-secondary-40) 0%,
        var(--amplify-colors-brand-secondary-60) 51%,
        var(--amplify-colors-brand-primary-60) 100%
    );
    white-space: nowrap;
    font-weight: 600;
    color: white;
}

.tabitem {
    font-size: 12px;
}
