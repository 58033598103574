#fileUpload {
    border: none;
}

/* file upload button */
input[type="file"] {
    width: 20rem;
}

input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 2rem;
    cursor: pointer;
    background-color: white;
    border: 1px solid hsl(0, 0%, 80%);
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}

.fileUploader {
    display: flex;
}

.fileUploaderButton {
    align-self: flex-start;
}

.folderContainer {
    margin-top: 2rem;
    gap: 6rem;
    flex-wrap: wrap;
}

.folder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 14rem;
    align-items: flex-start;
    cursor: pointer;
}

.folder svg {
    width: auto !important;
    height: 125px !important;
    cursor: pointer;
}

.folder:hover svg path {
    fill: black;
}

.folderDetails {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.folderActions {
    color: #555;
    cursor: pointer;
}

.folderActions:hover {
    color: black;
}
