.compSearch {
    width: 15rem;
}

.compSearch div {
    cursor: pointer;
}

.compCard {
    justify-content: space-between;
    width: 15rem;
    padding: 1rem;
    text-align: center;
}

.compCard .compRemoveButton {
    margin-top: 1rem;
}