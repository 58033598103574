.chatMessage {
  flex-direction: 'row';
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  background: var(--cards-chat-box, #F1F5FF);
  border-radius: 4px;
  border: 1px solid var(--components-fieldcontrol-disabled-border-color, rgba(0, 0, 0, 0.00));
}

.chatMessage p {
  margin: 0;
}

.chatPrompt {
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 4px;
}

.chatPrompt input {
  background: #FFF;
}

.chatPromptButton {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--gradients-logo, #7AE7FF);
}

.chatPromptButton svg path {
  fill: #6A98FF;
}

.chatPromptButton p {
  background: var(--gradients-logo, linear-gradient(221deg, #7AE7FF -3.86%, #6A98FF 39.78%, #7856FF 96.43%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chatPromptButton:hover {
  border: 1px solid var(--gradients-logo, #7AE7FF);
  background: var(--cards-chat-box, #F1F5FF);
}

.clearChatButton {
  align-self: flex-start;
  justify-content: flex-start;
}

.clearChatButton svg {
  width: 14px !important;
  height: 14px !important;
  margin-right: 0.5rem;
}

.clearChatButton svg path {
  fill: rgb(114, 121, 142);
}

.aiDisclaimer {
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  gap: 0.5rem;
  align-items: center;
  border-radius: 4px;
  background: var(--components-alert-info-background-color, #B8CEF9);
}

.chatGPTButton {
    position: fixed;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 1rem;
    transition: all 0.2s ease;
    background-image: linear-gradient(
      to right,
      var(--amplify-colors-brand-secondary-40) 0%,
      var(--amplify-colors-brand-secondary-60) 51%,
      var(--amplify-colors-brand-primary-60) 100%
    );
    background-color: var(--amplify-colors-brand-secondary-60);
    z-index: 10;
  }
  
  .chatGPTButton:hover {
    transform: scale(1.05);
  }

  .chatGPTButton svg path {
    fill: #EFF0F0;
  }

  .chatGPTButton p {
    color: #EFF0F0;
  }
  
  .chatGPTModalWrapper {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: var(--amplify-colors-overlay-60);
  }
  
  .chatGPTModal {
    position: absolute;
    width: 30rem;
    height: 0rem;
    padding: 0 0 1rem 0;
    top: 1rem;
    right: 7rem;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.75);
    transition: all 0.5s ease;
  }
  
  .chatGPTModal.active {
    height: 95vh;
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
  }
  
  .chatGPTModal.active.full {
    width: 90vw;
    height: 95vh;
  }

  .chatGPTModal .chatGPTHeader {
    align-items: center;
    justify-content: flex-end;
    background-color: var(--amplify-colors-brand-secondary-60);
    box-shadow: var(--amplify-colors-overlay-20) 0px 5px 10px 0px;
  }
  
  .chatGPTModal .chatGPTHeader h5 {
    margin-right: auto;
    margin-bottom: 0;
    color: white;
    padding: 1rem;
  }
  
  .chatGPTModal .chatGPTHeader {
    padding-right: 1rem;
  }
  
  .chatGPTModal .chatGPTHeader svg:hover {
    cursor: pointer;
  }
  
  .chatGPTModal .chatGPTHeader svg path {
    fill: white;
  }
  
  .chatGPTModal .chatGPTHeader svg:hover path {
    opacity: 0.7;
  }
  
  .chatGPTModal .chatGPTBody {
    flex-direction: column;
    gap: 1.5rem;
    height: 42rem;
    overflow: scroll;
    padding: 0 1rem 1rem 1rem;
  }
  
  .chatGPTModal .chatGPTBody .userMessage {
    position: relative;
    background-color: var(--amplify-colors-brand-secondary-60);
    color: white;
    padding: 0.75rem;
    border-radius: 1rem;
  }
  
  .chatGPTModal .chatGPTBody .userMessage:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid var(--amplify-colors-brand-secondary-60);
    border-right: 5px solid transparent;
    border-bottom: 10px solid var(--amplify-colors-brand-secondary-60);
    border-top: 0 solid transparent;
    right: -4px;
    bottom: 0;
  }
  
  .chatGPTModal .chatGPTBody .chatGPTMessage {
    position: relative;
    background-color: var(--amplify-colors-shadow-tertiary);
    color: black;
    padding: 0.75rem;
    border-radius: 1rem;
  }
  
  /* TODO: add speech bubble styles for ChatGPT messages */
  
  .chatGPTModal .chatGPTControls {
    display: none;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 0rem;
  }

  .chatGPTModal.active .chatGPTControls {
    display: flex;
    margin-top: auto;
  }
  
  .chatGPTModal .chatGPTControls .chatGPTPromptSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .25rem;
    gap: 1rem;
  }
  
  .chatGPTModal .chatGPTControls .chatGPTPromptSection .userPrompt {
    width: 95%;
  }
  
  .chatGPTModal .chatGPTControls .chatGPTPromptSection .userPrompt .amplify-textarea {
    border-radius: 2rem;
    border-color: var(--amplify-colors-brand-secondary-20);
  }
  
  .chatGPTModal .chatGPTControls .chatGPTPromptSection .userPrompt .amplify-textarea:focus {
    box-shadow: none;
  }
  
  .chatGPTModal .chatGPTControls .chatGPTPromptSection .userPromptSendButton {
    height: 2rem;
    border: 1px solid var(--amplify-colors-brand-secondary-20);
  }
  
  .chatGPTModal .chatGPTControls .clearChatGPTButton {
    width: 7rem;
    align-self: center;
  }