.taxonomyTagContainer {
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.taxonomyTag {
  padding: 6px;
  border-radius: 4px;
  background: var(--colors-neutral-20, #EFF0F0);
}

.taxonomyTag svg {
  width: 1rem !important;
  height: 1rem !important;
}

.taxonomyTag p {
  font-size: 12px;
  font-weight: 400;
}

.productTable .taxonomyTag {
  background: var(--colors-white, #FFF);
}

.productTable thead th {
  font-size: 1rem;
}

.baseCard.customerCard {
  padding-bottom: 1rem;
}

.baseCard.customerCard p, .baseCard.marketCard p {
  margin: 0;
}

.baseCard.marketCard {
  background-color: transparent;
  border: none;
  border-radius: 16px;
  background: transparent !important;
  /* glassmorphism shadow */
  box-shadow: none;
  padding: 1rem 0.5rem;
}

.baseCard.marketCard .header5 {
  color: var(--components-badge-info-color, #1C4E98);
}

.baseCard.marketCard .mitigantCard {
  padding: 8px 20px;
  border-radius: 4px;
  background: var(--table-cell-header-2, #E4EDFF);
}

.baseCard.marketCard .mitigantCard .amplify-text {
  font-weight: 600;
}

.docCard {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--table-cell-outline, #D4D4D4);
  background: #FFF;
}

.baseCard.gradientCard {
  position: relative;
  border: 0px solid transparent;
  border-radius: 1.5rem;
}

.baseCard.gradientCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1rem;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #6EE3FD, #743ad5) border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.baseCard.gradientCard .amplify-flex {
  /* place all card content above gradient border mask */
  z-index: 1;
}

.actionButton {
  cursor: pointer;
}

.actionButton:hover {
  opacity: 0.8;
}

.documentCard {
  border-radius: 8px;
  border: 1px solid var(--table-cell-outline, #D4D4D4);
  background: var(--cards-card-background-dark, rgba(255, 255, 255, 0.80));
}

.documentUploadButton {
  width: 1rem;
  margin-right: 0.5rem;
}

.documentUploadButton path {
  fill: #2962E3;
}