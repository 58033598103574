.equityCalculatorHeader {
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;

  .learnMoreButtons {
    align-items: center;
  }
}

.equityCalculatorOutput {
  width: 45rem;
  height: 20rem;
}

.largeModal {
  width: 90vw;
}

.smallModal {
  width: 39rem;

  .processList {
    margin: 0;

    .processListItem {
      padding-bottom: 0.5rem;
    }
  }
}

@media screen and (max-width: 970px) {
  .equityCalculatorHeader {
    flex-direction: column;
  }
  .learnMoreButtons {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .equityCalculatorOutput {
    width: 35rem;
  }
}

@media screen and (max-width: 730px) {
  .recharts-responsive-container {
    overflow: scroll;
  }

  .smallModal {
    width: 90vw;
  }
  // .equityCalculatorOutput {
  //   width: 25rem;
  // }
}

@media screen and (max-width: 540px) {
  // .equityCalculatorOutput {
  //   width: 18rem;
  // }
}
