/* GENERAL */
.sideNav {
    height: auto !important;
    width: inherit;
    background-color: transparent !important;
    transition: all 0.5s ease;
    overflow: visible !important;
}

.sideNav .logo {
    cursor: pointer;
}

.sideNav.darkmode svg path {
    fill: var(--amplify-colors-neutral-20);
}

.sideNav.darkmode p {
    color: var(--amplify-colors-neutral-20) !important;
}

.sideNav div {
    overflow: visible !important;
    width: 100%;
}

.sideNav.collapsed {
    width: 4rem !important;
}

.sideNav.collapsed .menuitem:hover .tooltip .tooltipText {
    visibility: visible;
}

.sideNav p {
    position: relative !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

/* TRANSACTION SELECT */
.sideNav .transactionSelect {
    width: 14rem;
    margin: 0.5rem;
    margin-left: 1rem;
}

.sideNav .transactionSelect input {
    font-size: 12px;
    color: #000;
    padding-left: 2rem;
    padding-right: 2rem;
}

.sideNav.darkmode .transactionSelect input {
    color: #FFF;
}

.sideNav .transactionSelect div {
    width: auto;
}

/* MENU ITEMS */
.sideNav .menuitems {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: auto;
}

.sideNav .menuitemContainer {
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.sideNav .menuitem {
    align-items: center;
    padding-left: 0.5rem;
    width: inherit;
    height: 2.5rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.sideNav .menuitem svg {
    margin-left: 7px !important;
}

.sideNav .menuitem .menulabel {
    pointer-events: inherit;
}

.sideNav.collapsed .menuitem .menulabel {
    pointer-events: none;
}

.sideNav.collapsed .menuitem .menuitemExpand {
    display: none;
}

.sideNav .menuitem::before {
    content: '';
    position: absolute;
    left: 0.5rem;
    height: 2.5rem;
    border-left: 5px solid var(--components-button-color, #1C70EE);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    opacity: 0;
    visibility: hidden;
}

.sideNav.darkmode .menuitem::before {
    border-left: 5px solid #FFF;
}

.sideNav .menuitem div {
    width: auto !important;
}

.sideNav .menuitem.divider {
    margin-top: 1.5rem !important;
    margin-bottom: -0.5rem !important;
    pointer-events: none;
}

.sideNav .menuitem.divider:first-child {
    margin-top: 0 !important;
}

.sideNav .menuitem.divider p {
    margin-left: 1rem !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
}

.sideNav.darkmode .menuitem.divider p {
    color: var(--amplify-colors-neutral-40) !important;
}

.sideNav .menuitem p, .sideNav .submenuitem p {
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: opacity 0.2s ease;
    transition-delay: 0.3s;
    opacity: 1;
}

.sideNav.collapsed .menuitem p, .sideNav.collapsed .submenuitem p {
    transition: opacity 0.2s ease;
    opacity: 0;
    pointer-events: none;
}

.sideNav .menuitem.selected::before {
    opacity: 1;
    visibility: visible;
}

.sideNav .menuitem.selected {
    background: var(--components-button-hover-color, #CFE2FF);
}

.sideNav.darkmode .menuitem.selected {
    background: rgba(255, 255, 255, 0.20);
}

.sideNav .menuitem.selected svg path {
    fill: var(--amplify-colors-brand-primary-60);
}

.sideNav.darkmode .menuitem.selected svg path {
    fill: var(--amplify-colors-neutral-20);
}

.sideNav .menuitem.selected p {
    color: var(--amplify-colors-brand-primary-60) !important;
}

.sideNav.darkmode .menuitem.selected p {
    color: var(--amplify-colors-neutral-20) !important;
}

.sideNav .menuitem:hover {
    cursor: pointer;
}

.sideNav .menuitem:hover {
    background: var(--components-button-hover-color, #CFE2FF);
}

.sideNav.darkmode .menuitem:hover {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.20);
}

.sideNav .menuitem.divider:hover {
    background-color: transparent;
    cursor: default;
}

.sideNav .menuitem.divider:hover p {
    color: var(--amplify-colors-brand-primary-40) !important;
}

.sideNav.darkmode .menuitem.divider:hover p {
    color: var(--amplify-colors-neutral-20) !important;
}

.sideNav .menuitem:hover p {
    color: var(--amplify-colors-brand-primary-60) !important;
}

.sideNav.darkmode .menuitem:hover p {
    color: var(--amplify-colors-neutral-20) !important;
}

.sideNav .menuitem:hover svg path {
    fill: var(--amplify-colors-brand-primary-60);
}

.sideNav.darkmode .menuitem:hover svg path {
    fill: var(--amplify-colors-neutral-20);
}

.sideNav .menuitem.selected:hover svg path {
    fill: var(--amplify-colors-brand-primary-60);
}

.sideNav.darkmode .menuitem.selected:hover svg path {
    fill: var(--amplify-colors-neutral-20);
}

.sideNav .menuitem.selected:hover p {
    color: var(--amplify-colors-brand-primary-60) !important;
}

.sideNav.darkmode .menuitem.selected:hover p {
    color: var(--amplify-colors-neutral-20) !important;
}

.sideNav .menuitem.disabled {
    pointer-events: none;
}

.sideNav .menuitem.disabled svg path {
    fill: var(--amplify-colors-font-disabled);
}

.sideNav .menuitem.disabled p {
    color: var(--amplify-colors-font-disabled) !important;
}

.sideNav .menuitem .menuitemExpand {
    border: solid var(--amplify-colors-brand-primary-60);
    border-width: 0 1px 1px 0;
    padding: 3px;
    margin-left: -5px;
    transform: rotate(-45deg);
    transition: transform 0.2s;
}

.sideNav.darkmode .menuitem .menuitemExpand {
    border: solid var(--amplify-colors-neutral-20);
    border-width: 0 1px 1px 0;
}

.sideNav .menuitem:hover .menuitemExpand {
    border: solid var(--amplify-colors-brand-primary-60);
    border-width: 0 1px 1px 0;
}

.sideNav.darkmode .menuitem:hover .menuitemExpand {
    border: solid var(--amplify-colors-neutral-20);
    border-width: 0 1px 1px 0;
}

.sideNav .menuitemExpand, .sideNav .menuitem .menuitemExpand.active {
    transform: rotate(45deg);
    opacity: 1;
}

.sideNav .amplify-flex:last-child {
    margin-top: auto;
}

/* SUB MENU ITEMS */
.sideNav .submenuitems {
    display: none;
    flex-direction: column;
    padding-left: 2rem;
    gap: 5px;
}

.sideNav.darkmode .submenuitems {
    /* border: 1px solid transparent; */
}

.sideNav .menuitemContainer.selected .submenuitems {
    display: flex;
}

.sideNav .menuitemContainer .submenuitems.active {
    display: flex;
}

.sideNav.collapsed .menuitemContainer .submenuitems {
    display: none;
}

.sideNav .submenuitems .submenuitem {
    height: 2.5rem;
    align-items: center;
    padding-left: 10px;
    border-radius: 0.25rem;
    cursor: pointer;
}

.sideNav .submenuitems .submenuitem::before {
    content: '';
    position: absolute;
    left: 40px;
    height: 2.5rem;
    border-left: 5px solid var(--components-button-color, #1C70EE);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    opacity: 0;
    visibility: hidden;
}

.sideNav.darkmode .submenuitems .submenuitem::before {
    border-left: 5px solid #FFF;
}

.sideNav .submenuitems .submenuitem.selected::before {
    opacity: 1;
    visibility: visible;
}

.sideNav .submenuitems .submenuitem.selected {
    background: var(--components-button-hover-color, #CFE2FF);
    background-clip: padding-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.sideNav.darkmode .submenuitems .submenuitem.selected {
    background: rgba(255, 255, 255, 0.20);
}

.sideNav .submenuitems .submenuitem.selected p {
    color: var(--amplify-colors-brand-primary-60) !important;
    font-weight: 600 !important;
}

.sideNav.darkmode .submenuitems .submenuitem.selected p {
    color: var(--amplify-colors-neutral-20) !important;
}

.sideNav .submenuitems .submenuitem:first-child {
    margin-top: 0.4rem;
}

.sideNav .submenuitems .submenuitem .submenulabel {
    width: inherit !important;
}

.sideNav .submenuitems .submenuitem:hover {
    background: var(--components-button-hover-color, #CFE2FF);
    background-clip: padding-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.sideNav.darkmode .submenuitems .submenuitem:hover {
    background: rgba(255, 255, 255, 0.20);
}

.sideNav .submenuitems .submenuitem:hover p {
    color: var(--amplify-colors-brand-primary-60) !important;
}

.sideNav.darkmode .submenuitems .submenuitem:hover p {
    color: var(--amplify-colors-neutral-20) !important;
}

.sideNav .opportunityName {
    padding-left: 0.5rem;
    font-weight: 600;
    color: #000;
}

.sideNav.darkmode .opportunityName {
    color: #FFF;
}

/* FOOTER */
.sideNav .footer {
    position: relative;
    width: 250px;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 2rem;
}

.sideNav.collapsed .footer {
    width: 4rem;
}

.sideNav .footer img {
    cursor: pointer;
}

.sideNav .footer svg:hover path {
    fill: var(--amplify-colors-brand-primary-60);
}

.sideNav.darkmode .footer svg:hover path {
    fill: var(--amplify-colors-neutral-20);
}

.sideNav .footer .username {
    display: inherit;
    width: max-content !important;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
    color: var(--amplify-colors-brand-primary-40);
    cursor: pointer;
    pointer-events: inherit;
    transition: all 0.2s ease;
    transition-delay: 0.1s;
}

.sideNav.darkmode .footer .username {
    color: var(--amplify-colors-neutral-20);
}

.sideNav .footer .username:hover {
    color: var(--amplify-colors-brand-primary-60);
}

.sideNav.darkmode .footer .username:hover {
    color: var(--amplify-colors-neutral-20);
}

.sideNav.collapsed .username {
    opacity: 0;
    pointer-events: none;
}

.sideNavToggle {
    opacity: 0;
    position: absolute;
    right: -10px;
    transition: all 0.2s ease;
}

.sideNav.collapsed .sideNavToggle {
    right: 0;
}

.sideNav:hover .sideNavToggle {
    opacity: 1;
}
