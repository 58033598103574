.view {
  padding: 2rem;
  border-radius: 0.5rem;
  overflow: scroll;
  transition: all 0.5s ease;
}

.view.darkmode .privateModeHeader {
  margin-left: auto;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  justify-content: flex-end;
}

.view.darkmode .privateModeHeader p {
  color: #FFF;
}

.view.darkmode .privateModeHeader svg {
  width: 1rem !important;
}

.view.darkmode .privateModeHeader svg path {
  fill: #FFF;
}

/* AMPLIFY COMPONENTS */
.amplify-heading--3 {
  font-size: 32px;
}

.header3 {
  font-size: 24px;
  font-weight: 600;
}

.header4 {
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.05em;
}

.amplify-heading--5 {
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 22px;
}

.header5 {
  font-size: 1rem;
  color: var(--amplify-colors-brand-primary-40);
  font-weight: 600;
}

.amplify-heading--6 {
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 14px;
}

.header6 {
  width: 180px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.amplify-badge {
  align-items: center;
  box-shadow: 1px 1px 5px var(--amplify-colors-shadow-tertiary);
  background: var(--components-alert-info-backgroundColor, #B8CEF9);
}

.aiBadge {
  gap: 0.5rem;
  padding: 4px 8px;
  border-radius: 0.25rem;
  color: #FFF;
  background: var(--gradients-logo, linear-gradient(221deg, #7AE7FF -3.86%, #6A98FF 39.78%, #7856FF 96.43%));
}

.aiBadge svg {
  width: 1rem !important;
}

.aiBadge svg path {
  fill: #FFF;
}

.css-1p3m7a8-multiValue {
  min-width: auto !important;
  background-color: var(--amplify-components-badge-background-color) !important;
  border-radius: var(--amplify-components-badge-border-radius) !important;
  color: var(--amplify-components-badge-color) !important;
  display: inline-flex !important;
  font-size: var(--amplify-components-badge-font-size) !important;
  font-weight: var(--amplify-components-badge-font-weight) !important;
  line-height: var(--amplify-components-badge-line-height) !important;
  padding: var(--amplify-components-badge-padding-vertical) var(--amplify-components-badge-padding-horizontal) !important;
  text-align: var(--amplify-components-badge-text-align) !important;
}

.css-12a83d4-MultiValueRemove {
  border-radius: 1rem !important;
}

.amplify-button {
  white-space: nowrap;
  font-weight: 700;
  color: var(--amplify-colors-brand-secondary-60);
  border-color: var(--amplify-colors-brand-secondary-60);
}

.amplify-button:focus {
  box-shadow: none;
}

.amplify-button svg path {
  fill: #1C70EE;
}

.amplify-button.save, .amplify-button.save:active, .amplify-button.save:focus {
  align-self: flex-start;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid var(--colors-green-60, #32B448);
  background: var(--colors-green-60, #32B448);
}

.amplify-button.save:hover {
  color: #FFF;
  border: 1px solid var(--colors-green-60, #32B448);
  background: var(--colors-green-40, #77CA85);
}

.amplify-button.save svg {
  margin-right: 0.5rem;
}

.amplify-button--small {
  color: var(--components-button-color, #1C70EE);
  border-radius: 4px;
  border: 1px solid var(--components-button-color, #1C70EE);
}

.amplify-button--small:hover, .amplify-button--small:focus, .amplify-button--small:active {
  color: var(--components-button-color, #1C70EE);
  border-radius: 4px;
  border: 1px solid var(--components-button-color, #1C70EE);
  background: var(--components-button-hover-background-color, #D9E6FF);
}

.amplify-button--small.active {
  background: var(--components-button-color, #1C70EE);
}

.amplify-button--small.active svg path {
  fill: #FFF;
}

.amplify-button--primary, .amplify-button--primary:active, .amplify-button--primary:focus {
  color: #FFF;
  border-radius: 4px;
  border: 1px solid var(--components-button-color, #1C70EE);
  background: var(--components-button-color, #1C70EE);
}

.amplify-button--primary:hover {
  color: #FFF;
  border-radius: 4px;
  border: 1px solid var(--components-button-border-color, #1C70EE);
  background: var(--components-button-primary-hover-background-color, #679AFF);
  box-shadow: none;
}

.amplify-button--primary svg path {
  fill: #FFF;
}

.amplify-button--primary.round {
  border-radius: 2rem;
}

.amplify-button--primary.amplify-button--disabled {
  border: 1px solid var(--components-button-disabled-color, #A0A2AC);
  background: var(--components-button-disabled-color, #A0A2AC);
}

.amplify-button--link {
  border: none;
}

.amplify-button--link:hover {
  border: none;
  color: var(--components-button-primary-hover-background-color, #679AFF);
  background: transparent;
}

.amplify-button--destructive {
  background: transparent;
  color: var(--colors-red-60, #BF4040);
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid var(--colors-red-60, #BF4040);
}

.amplify-button--destructive:hover, .amplify-button--destructive:focus, .amplify-button--destructive:active {
  background: var(--colors-red-10, #FCE9E9);
  color: var(--colors-red-60, #BF4040);
  border: 1px solid var(--colors-red-60, #BF4040);
}

.amplify-button--destructive svg {
  margin-right: 0.5rem;
}

.amplify-button--destructive svg path {
  fill: #BF4040;
}

.amplify-button.tertiary {
  color: var(--colors-brand-primary-20, #72798E);
  border: 1px solid var(--colors-brand-primary-20, #72798E);
}

.amplify-button.tertiary:focus {
  background-color: transparent;
}

.amplify-button.tertiary:hover {
  background: rgb(220,220,220);
}

.amplify-button.aiButton, .amplify-button.requestFeatureButton {
  border: 1px solid var(--gradients-logo, #7AE7FF);
  background: var(--gradients-logo, linear-gradient(221deg, #7AE7FF -3.86%, #6A98FF 39.78%, #7856FF 96.43%));
}

.amplify-button.aiButton svg, .amplify-button.requestFeatureButton svg {
  margin-right: 0.5rem;
}

.amplify-button.aiButton .amplify-text, .amplify-button.requestFeatureButton .amplify-text {
  color: #FFF;
}

.amplify-button.aiButton:hover, .amplify-button.requestFeatureButton:hover {
  border: 1px solid var(--gradients-logo, #7AE7FF);
  background: var(--colors-white, #FFF);
  background: var(--gradients-logo, linear-gradient(221deg, #7AE7FF -3.86%, #6A98FF 39.78%, #7856FF 96.43%));
  opacity: 0.8;
  cursor: pointer;
}

.amplify-button.aiButton:hover .amplify-text, .amplify-button.requestFeatureButton:hover .amplify-text {
  /* color: #6EE3FD; */
  background: linear-gradient(90deg, #6EE3FD -14.91%, #5C8AFF 59.04%, #674BF3 122.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.amplify-button.aiButton:hover svg path, .amplify-button.requestFeatureButton:hover svg path {
  fill: #5C8AFF;
}

.amplify-button.aiPromptButton {
  border-radius: 4px;
  background: var(--colors-white, #FFF);
  border: 1px solid var(--table-cell-outline, #DCDEE0);
}

.amplify-button.aiPromptButton:hover {
  background: var(--components-button-hover-color, #CFE2FF);
}

.amplify-button.aiPromptButton p {
  font-weight: 700;
  color: var(--components-button-color, #1C70EE);
}

.amplify-button.excel, .amplify-button.excel:active, .amplify-button.excel:focus {
  background: #2c6534;
  border: 1px solid #2c6534;
}

.amplify-button.excel:hover {
  background: #46945d;
  border: 1px solid #2c6534;
}

.amplify-button.excel svg path {
  fill: #FFF;
}

.imageButton {
  min-width: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.imageButton:hover {
  transform: scale(1.1);
}

.amplify-card--elevated {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--components-card-border-color, #FFF);
  background: var(--cards-card-background, rgba(255, 255, 255, 0.80));
  /* glassmorphism shadow */
  box-shadow: 0px 2px 8px 0px rgba(147, 155, 184, 0.40);
}

.css-b62m3t-container {
  width: 100%;
  min-width: 30rem;
}

.scenarioSelect {
  min-width: 15rem;
  z-index: 2;
}

.transactionTypeSelect {
  min-width: 10rem;
}

.css-b62m3t-container div {
  cursor: pointer;
}

.amplify-label {
  font-weight: 600;
}

.amplify-input {
  border-radius: 4px;
  border: 1px solid var(--components-fieldcontrol-border-color, #D4D4D4);
}

.amplify-input.amplify-input--quiet {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--components-fieldcontrol-border-color, #D4D4D4);
}

.amplify-input:focus, .amplify-select:focus {
  box-shadow: none;
}

.amplify-select {
  border: 1px solid var(--components-fieldcontrol-border-color, #D4D4D4);
  background-color: #FFF;
  cursor: pointer;
}

.amplify-link, a:visited {
  font-weight: 700;
  color: var(--components-button-color, #1C70EE) !important;
}

.amplify-link:hover {
  color: var(--components-button-primary-hover-background-color, #679AFF) !important;
}

.amplify-tabs {
  height: 40px;
  border: none;
  overflow: hidden;
}

.amplify-tabs__item {
  font-size: 14px;
  padding: 10px;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom: none;
}

.view.darkmode .amplify-tabs__item {
  color: #FFF;
}

.amplify-tabs__item--active {
  color: #FFF;
  background: var(--components-button-color, #1C70EE);
}

.amplify-tabs__item--active:hover {
  color: #FFF;
}

.view.darkmode .amplify-tabs__item--active {
  color: var(--components-button-primary-link-color, #3D4357);
  color: #FFF;
  background: var(--components-button-color, #1C70EE);
}

.amplify-loader {
  width: 3rem;
}

/* CUSTOM COMPONENTS */
.baseCard {
  padding: 24px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 0.5rem;
  background: var(--cards-card-background, rgba(255, 255, 255, 0.50));

  /* glassmorphism shadow */
  box-shadow: 0px 2px 8px 0px rgba(147, 155, 184, 0.40);
}

.shadow {
  box-shadow: 0px 2px 8px 0px rgba(147, 155, 184, 0.40);
}

.view.darkmode .baseCard {
  background: rgba(255, 255, 255, 0.90);
}

.baseCard.firstCard {
  border-top-left-radius: 0;
}

.baseCard.lastCard {
  border-top-right-radius: 0;
}

.baseCard.companyHeaderCard {
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
}

.baseCard.overlayCard {
  border-radius: 6px;
  border: 1px solid var(--card-outline, #D4D4D4);
  background: var(--cards-card-background, rgba(255, 255, 255, 0.50));
  box-shadow: none;
}

.baseCard.white {
  background: #FFF;
}

.baseCard.aiCard {
  border-radius: 8px;
  border: 1px solid var(--gradients-logo, #7AE7FF);
  background: var(--cards-card-background-dark, rgba(255, 255, 255, 0.85));
}

.baseCard.aiCard .cardContent {
  gap: 0;
}

.baseCard.aiCard .header4 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  background: var(--gradients-logo, linear-gradient(221deg, #7AE7FF -3.86%, #6A98FF 39.78%, #7856FF 96.43%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.baseCard.overflow {
  overflow: scroll;
}

.baseCard .header4 {
  margin-bottom: 0.5rem;
}

.homePageSearchCard {
  position: absolute;
  width: 45rem;
  height: 14rem;
  top: 50%;
  left: 50%;
  margin: -14rem 0 0 -18rem;
}

.modal {
  width: 80vw;
  background: #FFF;
  z-index: 2;
}

.modal .cardContent {
  gap: 2rem;
}

.baseCard.marketSummaryCard .header5 {
  font-size: 1.15rem;
}

.baseCard.marketSummaryCard .marketSummaryHeading {
  color: var(--colors-brand-primary-60, #3D4357);
  font-weight: 600;
  margin-bottom: 0;
}

.baseCard.marketSummaryCard .marketSummaryMetric {
  color: var(--components-badge-info-color, #1C4E98);
  font-size: 1rem;
  font-weight: 600;
}

.baseCard.marketSummaryCard .marketSummaryMetricDescription {
  color: var(--colors-brand-primary-60, #3D4357);
  font-weight: 300;
}

.baseCard.marketSummaryCard .marketSummarySource {
  flex-direction: column;
  gap: 0;
  margin-top: 0.5rem;
  font-size: 10px;
  font-weight: 600;
}

.baseCard.marketSummaryCard .marketSummarySource a {
  font-weight: 400;
}

.baseCard.crmCard p {
  margin: 0;
}

.baseCard.relationshipsCard {
  width: 30rem;
  overflow: hidden;
}

.baseCard.relationshipsCard .relationshipsCardContent {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 1rem; /* increase / decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* so the width will be 100% + 17px */
}

.benchmarksContainer {
  padding: 0 1rem 1rem 0;
  gap: 1.5rem;
}

.filtersContainer {
  height: fit-content;
  padding: 1rem;
  border: 1px solid var(--card-outline, #D4D4D4);
}

.filtersContainer .clearLink {
  font-weight: bold;
  color: #636879;
  cursor: pointer;
}

.filtersContainer .clearLink:hover, .filtersContainer .loadLink:hover {
  opacity: 0.8;
}

.filterTopRow {
  padding: 1rem;
  border: 1px solid var(--card-outline, #D4D4D4);
  border-left: none;
}

.filterRow {
  gap: 0.25rem;
  align-items: center;
}

.filterRow .amplify-label {
  font-size: 0.75rem;
  color: var(--amplify-colors-brand-primary-60);
}

.filterRow .amplify-input {
  width: 8rem;
  font-size: 0.75rem;
}

.filterRow span {
  font-size: 0.75rem;
}

.filterRow small {
  color: var(--amplify-colors-brand-primary-40);
}

.filterRow .css-b62m3t-container {
  min-width: 0;
}

.filterCard {
  width: 20rem;
}

.filterCard .filterCardInput {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.filterCard .filterCardControls {
  justify-content: space-between;
}

.filterCard .clearLink {
  font-weight: bold;
  color: #636879;
  cursor: pointer;
}

.filterCard .clearLink:hover {
  opacity: 0.8;
}

.filterCard .deleteLink {
  font-weight: bold;
  color: #BF4040;
  cursor: pointer;
}

.filterCard .deleteLink:hover {
  opacity: 0.8;
}

.candlestickContainer {
  width: 12rem;
  margin-bottom: 2rem;
}

.candlestickLabel {
  justify-content: center;
  margin-bottom: 1rem;
}

.candlestickLabel p {
  font-weight: 600;
}

.candlestickBadgeContainer {
  flex-direction: column;
  align-items: end;
  gap: 0.5rem;
}

.candlestickBadge {
  width: 8rem;
  justify-content: center;
  font-size: 0.75rem;
  box-shadow: none;
  background: var(--amplify-components-badge-background-color);
}

.amplify-tabs__list {
  border: none;
}

.lightmodeTabs .amplify-tabs__item {
  color: rgba(99, 104, 121, 1) !important;
}

.lightmodeTabs .amplify-tabs__item[data-state='active'] {
  color: var(--components-button-primary-link-color, #3D4357) !important;
}

.darkmodeIcon path {
  stroke: #FFF;
}

.tooltip {
  position: relative;
}

/* tooltip copy */
.tooltip::before {
  /* hide by default */
  display: none;
  position: absolute;

  /* copy */
  content: attr(data-tooltip);

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;

  /* styles */
  width: 10rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 12px;
  background: #000;
  color: #FFF;
  text-align: center;
  z-index: 10;
}

.tooltip.down::before {
  left: initial;
  margin: initial;
  top: 100%;
  margin-top: 2rem;
  margin-left: -7rem;
}

.tooltip.down.large::before {
  margin-top: 3rem;
  width: 20rem;
}

/* tooltip arrow */
.tooltip:after {
  display: none;
  content: '';
  position: absolute;
  left: 100%;
  margin-left: -1rem;
  top: 50%;
  transform: translateY(-50%);
  border: 0.5rem solid #000;
  border-color: transparent #000 transparent transparent;
}

.tooltip.down:after {
  left: initial;
  margin: initial;
  top: 100%;
  /* TODO: hiding pointer for now but need to fix pointer position */
  border-color: transparent transparent transparent transparent;
  margin-top: 5px;
  margin-left: 1rem;
}

.tooltip.down.large::after {
  display: none;
}

.tooltip:hover::before, .tooltip:hover::after {
  display: block;
}

/* dropzone */
.dropZone {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 15rem;
  justify-content: center;
}

.dropZone .dropZoneIcon {
  align-self: center;
}

.dropZone .dropZoneSubtitle {
  color: var(--colors-brand-primary-40, #636879);
}

/* modal */
.ReactModal__Content {
  position: absolute !important;
  width: fit-content !important;
  height: fit-content !important;
  inset: 50% 40px 40px 50% !important;
  padding: 20px !important;
  outline: none !important;
  background: transparent !important;
  border-radius: 0px !important;
  border: 0px solid rgb(204, 204, 204) !important;
  transform: translate(-50%, -50%) !important;
  overflow: auto !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0,0,0,0.6) !important;
  z-index: 2 !important;
}

.ReactModal__Content .amplify-card {
  background: #FFF;
}

.ReactModal__Content .modalCard {
  width: 30rem;
  z-index: 2;
}

.ReactModal__Content .modalCard .amplify-field {
  width: 100%;
}

.ReactModal__Content .header4 {
  margin-bottom: 0;
}

.ReactModal__Content .cardContent .amplify-flex:first {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--table-cell-outline, #DCDEE0);
}

.ReactModal__Content .modalButtons {
  justify-content: flex-end;
  margin-top: 0.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--table-cell-outline, #DCDEE0);
}

/* react grid */
.reactgrid-content .rg-pane.rg-pane-left {
  position: sticky;
  z-index: 2;
}
