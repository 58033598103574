.app {
  grid-template-columns: auto 1fr;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: url(/public/BackgroundGradientLight.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: scroll;
  transition: all 0.5s ease;
}

.app.darkmode {
  background: url(/public/BackgroundGradientDark.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}